import { forwardRef } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "./DownloadSearchResults.css";
import { generateRegulationFileName, handleFileDownload } from "./handle-file-download";

const DownloadSearchResults = forwardRef(({
  title, data, regFilter, partsFilter, stateFilter, onClick, disabled,
}, ref) => {
  const handleClick = () => {
    const fileName = generateRegulationFileName({
      isList: data.length,
      regulationFilter: regFilter,
      stateFilter,
      partsFilter,
    });
    handleFileDownload(data, fileName);
  };

  return (
    <Tooltip title={title} placement="top">
      <IconButton
        ref={ref}
        className={disabled ? "download-search-results-button-disabled" : "download-search-results-button"}
        sx={{ width: "48px", height: "48px" }}
        onClick={onClick ?? handleClick}
        disabled={disabled}
      >
        <FileDownloadIcon />
      </IconButton>
    </Tooltip>
  );
});

DownloadSearchResults.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  regFilter: PropTypes.string.isRequired,
  partsFilter: PropTypes.string.isRequired,
  stateFilter: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DownloadSearchResults;
