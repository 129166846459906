import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import SpinnerContext from "./spinner-context";

const SpinnerProvider = ({ children }) => {
  const [isSpinning, setIsSpinning] = useState(false);

  /**
   * @param {boolean} [nextState] - next state of global spinner.
   *
   * Should not be called directly inside the body of any component.
   *
   * @example
   * const { toggleSpinner } = useSpinner()
   * const handleSave = () => {
   *  toggleSpinner();
   *
   *  try {
   *    const response = await save();
   *  } catch (err) {
   *    throw new Error();
   *  } finally {
   *    toggleSpinner();
   *  }
   * }
   */
  const toggleSpinner = useCallback(
    (nextState) => setIsSpinning((currentState) => nextState ?? !currentState),
    [isSpinning],
  );

  const contextValue = useMemo(() => ({ isSpinning, toggleSpinner }), [isSpinning, toggleSpinner]);

  return (
    <SpinnerContext.Provider value={contextValue}>
      {children}
    </SpinnerContext.Provider>
  );
};

SpinnerProvider.propTypes = {
  children: PropTypes.node,
};

export default SpinnerProvider;
