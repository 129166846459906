import * as XLSX from "xlsx-js-style";
import REGULATIONS_CONSTANTS from "../../constants/Regulations/Regulations";
import mapToXlsx from "./regulation-to-xlsx.mapper";

/**
 * Downloads regulation data as an Excel file.
 *
 * @param {Array<Object>|Object} regulationData - The data to include in the Excel file.
 * @param {string} fileName - The name of the Excel file to download.
 *
 * @returns {void}
 *
 * @description
 * Converts the given regulation data (object or array) into an Excel file with formatted
 * headers and columns, then triggers the file download.
 *
 * @example
 * handleFileDownload(regulationData, "Regulations");
 */
export const handleFileDownload = (regulationData, fileName) => {
  const regulationsArray = Array.isArray(regulationData) ? regulationData : [regulationData];

  const flattenedData = mapToXlsx(regulationsArray);

  const worksheet = XLSX.utils.json_to_sheet(flattenedData);
  const headers = Object.keys(flattenedData[0]);
  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
  worksheet["!autofilter"] = {
    ref: `A1:${XLSX.utils.encode_cell({ r: regulationData.length, c: headers.length - 1 })}`,
  };

  headers.forEach((_, colIndex) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
    worksheet[cellAddress].s = {
      font: { bold: true },
      fill: { fgColor: { rgb: "D3D3D3" } },
      border: { bottom: { style: "medium", color: { rgb: "000" } } },
    };
  });

  const columnsWidth = [
    { wch: REGULATIONS_CONSTANTS.REGULATION_TITLE.length * 3 },
    { wch: REGULATIONS_CONSTANTS.REGULATION_DESCRIPTION.length * 2 },
    { wch: REGULATIONS_CONSTANTS.REGULATION_RULE_NO.length + 2 },
    { wch: REGULATIONS_CONSTANTS.RESTRICTION.length + 2 },
    { wch: REGULATIONS_CONSTANTS.JURISDICTION_LEVEL.length + 2 },
    { wch: REGULATIONS_CONSTANTS.COUNTRY.length + 4 },
    { wch: REGULATIONS_CONSTANTS.STATE.length + 2 },
    { wch: REGULATIONS_CONSTANTS.COUNTY.length + 4 },
    { wch: REGULATIONS_CONSTANTS.CITY.length * 4 },
    { wch: REGULATIONS_CONSTANTS.TOTAL_PARTS.length + 2 },
    { wch: REGULATIONS_CONSTANTS.REGULATION_EFF_DATE.length + 2 },
    { wch: REGULATIONS_CONSTANTS.REGULATION_CREATED_DATE.length + 2 },
    { wch: REGULATIONS_CONSTANTS.REGULATION_CREATED_BY.length + 2 },
    { wch: REGULATIONS_CONSTANTS.REGULATION_MODIFIED_DATE.length + 2 },
    { wch: REGULATIONS_CONSTANTS.REGULATION_MODIFIED_BY.length + 2 },
    { wch: REGULATIONS_CONSTANTS.TAMS_LINE_ABBRV.length + 4 },
    { wch: REGULATIONS_CONSTANTS.PART_NUMBER.length + 4 },
    { wch: REGULATIONS_CONSTANTS.PART_DESCRIPTION.length * 3 },
    { wch: REGULATIONS_CONSTANTS.CLASS_CODE.length + 2 },
    { wch: REGULATIONS_CONSTANTS.PART_OBSOLETE_DATE.length + 2 },
    { wch: REGULATIONS_CONSTANTS.ALT_FIELD_LINE_ABBREV.length + 2 },
    { wch: REGULATIONS_CONSTANTS.ALT_PART_NUMBER.length + 2 },
    { wch: REGULATIONS_CONSTANTS.ALT_PART_DESCRIPTION.length * 2 },
    { wch: REGULATIONS_CONSTANTS.PART_EXPIRED_DATE.length + 2 },
    { wch: REGULATIONS_CONSTANTS.PART_CREATED_DATE.length + 2 },
    { wch: REGULATIONS_CONSTANTS.PART_CREATED_BY.length + 2 },
    { wch: REGULATIONS_CONSTANTS.PART_MODIFIED_DATE.length + 2 },
    { wch: REGULATIONS_CONSTANTS.PART_MODIFIED_BY.length + 2 },
  ];

  worksheet["!cols"] = columnsWidth;

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Regulation List");

  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

/**
 * Generates a regulation file name based on various filters and options.
 *
 * @param {Object} options - Configuration options for the file name.
 * @param {boolean} [options.isList=false] - Indicates if the file name should include a
 * list prefix.
 * @param {string} [options.stateFilter='ALL'] - The state filter to include in the file name.
 * @param {string} [options.regulationFilter] - The regulation filter to include. Defaults to
 * 'ALL_Regulations' if not provided.
 * @param {string} [options.partsFilter] - The parts filter to include. Defaults to 'All_Parts'
 * if not provided.
 * @returns {string} The generated regulation file name.
 *
 * @example
 * const fileName = generateRegulationFileName({
 *   isList: true,
 *   stateFilter: 'CA',
 *   regulationFilter: 'Reg123',
 *   partsFilter: 'Part456'
 * });
 * console.log(fileName);
 * // Output: "Regulation_List_Reg123_Parts_Part456_State_CA"
 */
export const generateRegulationFileName = ({
  isList = false,
  stateFilter,
  regulationFilter,
  partsFilter,
}) => {
  const regulationPrefix = isList ? "List_" : "";
  const regulationFilterText = regulationFilter ?? "ALL_Regulations";
  const partsFilterText = partsFilter ? `Parts_${partsFilter}` : "All_Parts";

  const isStateFilterEmptyOrAll = !stateFilter || stateFilter === "All";
  const stateFilterText = isStateFilterEmptyOrAll ? "" : `_State_${stateFilter}`;

  return `Regulation_${regulationPrefix}${regulationFilterText}_${partsFilterText}${stateFilterText}`;
};
