import { useContext } from "react";
import Spinner from "../components/Spinner";

/**
 *
 * Custom hook to access and control the global spinner state.
 *
 * Must be used within a `Spinner.Provider` and should not be called outside of
 * useEffect or Event Handlers.
 *
 * @returns {{ isSpinning: boolean, toggleSpinner: (state?: boolean) => void }}
 *   - `isSpinning`: A boolean indicating whether the spinner is currently active.
 *   - `toggleSpinner`: A function to toggle the spinner's state.
 *       - **No arguments**: Toggles the current state (on/off).
 *       - **With a boolean argument**: Explicitly sets the spinner's state.
 */
const useSpinner = () => {
  const context = useContext(Spinner.Context);

  if (!context) {
    throw new Error("useSpinner must be used within a SpinnerProvider.");
  }

  return context;
};

export default useSpinner;
