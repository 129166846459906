import { useContext } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import SpinnerContext from "./spinner-context";

const SpinnerOverlay = () => {
  const { isSpinning } = useContext(SpinnerContext);

  return (
    <Backdrop
      open={isSpinning}
      style={{ zIndex: 9999, color: "#fff" }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default SpinnerOverlay;
